
// css
require('../scss/vendor.scss');

// js
window.$ = window.jQuery  = require('jquery');
window.Popper             = require('popper.js').default;

require('bootstrap');
require('select2');

require('../node_modules/jquery-datetimepicker/build/jquery.datetimepicker.full.js');

require('../node_modules/datatables.net-dt/js/dataTables.dataTables.min.js');
require('./vendor/dataTables.bootstrap4.js');
